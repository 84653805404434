import userLocale from "../subdomains/user/locales/en";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////
  

  user: {
    ...userLocale,
  },
};

export default locale;
