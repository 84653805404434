import { Route, Routes } from "react-router-dom";

import { List, View } from "../pages";

import { UserLayout } from "../layouts/UserLayout";
import { commonRoutes } from "routes/route.common";

export const UserRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<View />} />
      </Route>
    </Routes>
  );
};
