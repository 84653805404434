import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useUpdateNotificationMutation,
  useNotificationQuery,
} from "../context/notification.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import {
  INotificationUpdateInput,
  NotificationChannelType,
  NotificationType,
} from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateNotificationInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listNotificationRoute } from "../../../routes/notification.base.route";
import { ExpandMore } from "@mui/icons-material";
import ReactQuill from "react-quill";
import parse from "html-react-parser";

const Update = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const [variable, setVariable] = useState<string>("");
  const { id } = useParams();
  if (!id) {
    navigate(listNotificationRoute());
  }

  // queries
  const {
    data: notificationData,
    isLoading: notificationIsLoading,
    error: notificationError,
  } = useNotificationQuery({ input: { _id: id! } });

  // mutations
  const [
    updateNotification,
    {
      data: updateNotificationData,
      isLoading: updateNotificationIsLoading,
      error: updateNotificationError,
    },
  ] = useUpdateNotificationMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        domain: "",
        name: "",
        type: NotificationType.user,
        channels: {
          email: {
            content: {
              en: "",
              tr: "",
            },
            type: NotificationChannelType.email,
            subject: {
              en: "",
              tr: "",
            },
            variables: [],
          },
          web: {
            content: {
              en: "",
              tr: "",
            },
            type: NotificationChannelType.web,
            variables: [],
            subject: {
              en: "",
              tr: "",
            },
          },
          push: {
            content: {
              en: "",
              tr: "",
            },
            type: NotificationChannelType.push,
            variables: [],
            subject: {
              en: "",
              tr: "",
            },
          },
          sms: {
            content: {
              en: "",
              tr: "",
            },
            type: NotificationChannelType.sms,
            variables: [],
            subject: {
              en: "",
              tr: "",
            },
          },
        },
      },
    },
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: validateUpdateNotificationInput,
    onSubmit: (values: IGraphqlVariables<INotificationUpdateInput>) => {
      updateNotification({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(notificationData);
    if (notificationData) {
      setValues({
        input: {
          name: notificationData?.name,
          type: notificationData?.type,
          domain: notificationData?.domain,
          channels: {
            ...notificationData?.channels,
          },
        },
      });
    }
  }, [notificationData, setValues]);

  useEffect(() => {
    if (updateNotificationData) {
      resetForm();
      enqueueSnackbar(t("main.notification.notification.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateNotificationData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateNotificationError as IBaseError,
      currentLanguage
    );
  }, [updateNotificationError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      notificationError as IBaseError,
      currentLanguage
    );
  }, [notificationError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(
      notificationActions.setTitle(t("main.notification.notification.update"))
    );
    dispatch(notificationActions.setBackButton(false));
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {notificationIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <TextField
              fullWidth
              name="input.domain"
              label={t("main.notification.notification.domain")}
              value={(values as any).input.domain}
              onChange={(e) => setFieldValue("input.domain", e.target.value)}
              onBlur={() => handleBlur("input.domain")}
              error={!!(errors as any).input?.domain}
              helperText={(errors as any).input?.domain}
              disabled
            />

            <TextField
              fullWidth
              name="input.name"
              label={t("main.notification.notification.name")}
              value={(values as any).input.name}
              onChange={(e) => setFieldValue("input.name", e.target.value)}
              onBlur={() => handleBlur("input.name")}
              error={!!(errors as any).input?.name}
              helperText={(errors as any).input?.name}
              disabled
            />

            <Autocomplete
              id="type"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={Object.values(NotificationType)}
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.notification.notification.type")}
                />
              )}
              value={(values as any).input.type}
              onChange={(e, value) => {
                setFieldValue("input.type", value);
              }}
              onBlur={() => handleBlur("input.type")}
            />

            {notificationData?.channels?.email && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  Email
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h6" sx={{ my: 2 }}>
                    EN
                  </Typography>
                  <TextField
                    fullWidth
                    name="input.channels.email.subject.en"
                    label={t("main.notification.notification.subjectEn")}
                    value={
                      (values as any)?.input?.channels?.email?.subject.en || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.email.subject.en",
                        e.target.value
                      );
                    }}
                    onBlur={() => handleBlur("input.channels.email.subject.en")}
                    error={
                      !!(errors as any)?.input?.channels?.email?.subject.en
                    }
                    helperText={
                      (errors as any)?.input?.channels?.email?.subject.en
                    }
                  />
                  <Stack p={2}>
                    {parse(
                      (values as any)?.input?.channels?.email?.content?.en || ""
                    )}
                  </Stack>
                  <TextField
                    fullWidth
                    name="input.channels.email.content.en"
                    label={"HTML Content EN"}
                    multiline
                    rows={15}
                    sx={{ my: 2 }}
                    value={
                      (values as any)?.input?.channels?.email?.content.en || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.email.content.en",
                        e.target.value
                      );
                    }}
                    onBlur={() => handleBlur("input.channels.email.content.en")}
                    error={
                      !!(errors as any)?.input?.channels?.email?.content.en
                    }
                    helperText={
                      (errors as any)?.input?.channels?.email?.content.en
                    }
                  />

                  <Typography variant="h6" sx={{ my: 2 }}>
                    TR
                  </Typography>
                  <TextField
                    fullWidth
                    name="input.channels.email.subject.tr"
                    label={t("main.notification.notification.subjectTr")}
                    value={
                      (values as any)?.input?.channels?.email?.subject.tr || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.email.subject.tr",
                        e.target.value
                      );
                    }}
                    onBlur={() => handleBlur("input.channels.email.subject.tr")}
                    error={
                      !!(errors as any)?.input?.channels?.email?.subject.tr
                    }
                    helperText={
                      (errors as any)?.input?.channels?.email?.subject.tr
                    }
                  />

                  <Stack p={2}>
                    {parse(
                      (values as any)?.input?.channels?.email?.content?.tr || ""
                    )}
                  </Stack>
                  <TextField
                    fullWidth
                    name="input.channels.email.content.tr"
                    label={"HTML Content TR"}
                    multiline
                    rows={15}
                    sx={{ my: 2 }}
                    value={
                      (values as any)?.input?.channels?.email?.content.tr || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.email.content.tr",
                        e.target.value
                      );
                    }}
                    onBlur={() => handleBlur("input.channels.email.content.tr")}
                    error={
                      !!(errors as any)?.input?.channels?.email?.content.tr
                    }
                    helperText={
                      (errors as any)?.input?.channels?.email?.content.tr
                    }
                  />

                  <Autocomplete
                    multiple
                    freeSolo
                    size="small"
                    id="input.channels.email.variables"
                    options={[]}
                    value={
                      (values as any).input?.channels.email?.variables || []
                    }
                    inputValue={variable}
                    onInputChange={(event, newInputValue) => {
                      setVariable(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      setFieldValue("input.channels.email.variables", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === ",") {
                        event.preventDefault();
                        const inputValueTrimmed = variable.trim();
                        if (inputValueTrimmed !== "") {
                          const updatedVariables = [
                            ...(values as any).input?.channels.email?.variables,
                            inputValueTrimmed,
                          ];
                          setFieldValue(
                            "input.channels.email.variables",
                            updatedVariables
                          );
                          setVariable("");
                        }
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            size="small"
                            variant="outlined"
                            label={option}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t(
                          "main.notification.notification.variables"
                        )}
                        error={Boolean(
                          (errors as any).input?.channels.email?.variables
                        )}
                        helperText={
                          (errors as any).input?.channels.email?.variables
                        }
                      />
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                SMS
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6" sx={{ my: 2 }}>EN</Typography>
                <TextField
                  fullWidth
                  name="input.channels.sms.subject.en"
                  label={t("main.notification.notification.subjectEn")}
                  value={(values as any)?.input?.channels?.sms?.subject.en || ""}
                  onChange={(e) => {
                    setFieldValue("input.channels.sms.subject.en", e.target.value);
                  }}
                  onBlur={() => handleBlur("input.channels.sms.subject.en")}
                  error={!!(errors as any)?.input?.channels?.sms?.subject.en}
                  helperText={(errors as any)?.input?.channels?.sms?.subject.en}
                />
                <ReactQuill
                  theme="snow"
                  value={(values as any)?.input?.channels?.sms?.content.en || ""}
                  onChange={(value) => {
                    setFieldValue("input.channels.sms.content.en", value);
                  }}
                  onBlur={() => handleBlur("input.channels.sms.content.en")}
                  placeholder={t("main.notification.notification.contentEn")}
                  scrollingContainer={".ql-editor"}
                  style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
                />
                {(errors as any)?.input?.channels?.sms?.content.en && (
                  <Typography color="error" variant="caption">
                    {(errors as any)?.input?.channels?.sms?.content.en}
                  </Typography>
                )}

                <Typography variant="h6" sx={{ my: 2 }}>TR</Typography>
                <TextField
                  fullWidth
                  name="input.channels.sms.subject.tr"
                  label={t("main.notification.notification.subjectTr")}
                  value={(values as any)?.input?.channels?.sms?.subject.tr || ""}
                  onChange={(e) => {
                    setFieldValue("input.channels.sms.subject.tr", e.target.value);
                  }}
                  onBlur={() => handleBlur("input.channels.sms.subject.tr")}
                  error={!!(errors as any)?.input?.channels?.sms?.subject.tr}
                  helperText={(errors as any)?.input?.channels?.sms?.subject.tr}
                />
                <ReactQuill
                  theme="snow"
                  value={(values as any)?.input?.channels?.sms?.content.tr || ""}
                  onChange={(value) => {
                    setFieldValue("input.channels.sms.content.tr", value);
                  }}
                  onBlur={() => handleBlur("input.channels.sms.content.tr")}
                  placeholder={t("main.notification.notification.contentTr")}
                  scrollingContainer={".ql-editor"}
                  style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
                />
                {(errors as any)?.input?.channels?.sms?.content.tr && (
                  <Typography color="error" variant="caption">
                    {(errors as any)?.input?.channels?.sms?.content.tr}
                  </Typography>
                )}
                <Autocomplete
                  multiple
                  freeSolo
                  size="small"
                  id="input.channels.sms.variables"
                  options={[]}
                  value={(values as any).input?.channels.sms?.variables || []}
                  inputValue={variable}
                  onInputChange={(event, newInputValue) => {
                    setVariable(newInputValue);
                  }}
                  onChange={(event, newValue) => {
                    setFieldValue("input.channels.sms.variables", newValue);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ',') {
                      event.preventDefault();
                      const inputValueTrimmed = variable.trim();
                      if (inputValueTrimmed !== '') {
                        const updatedVariables = [...(values as any).input?.channels.sms?.variables, inputValueTrimmed];
                        setFieldValue("input.channels.sms.variables", updatedVariables);
                        setVariable('');
                      }
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={key}
                          size="small"
                          variant="outlined"
                          label={option}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={t("main.notification.notification.variables")}
                      error={Boolean((errors as any).input?.channels.sms?.variables)}
                      helperText={(errors as any).input?.channels.sms?.variables}
                    />
                  )}
                />

              </AccordionDetails>
            </Accordion> */}
            {notificationData?.channels?.web && (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  WEB
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h6" sx={{ my: 2 }}>
                    EN
                  </Typography>
                  <TextField
                    fullWidth
                    name="input.channels.web.subject.en"
                    label={t("main.notification.notification.subjectEn")}
                    value={
                      (values as any)?.input?.channels?.web?.subject.en || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.web.subject.en",
                        e.target.value
                      );
                    }}
                    onBlur={() => handleBlur("input.channels.web.subject.en")}
                    error={!!(errors as any)?.input?.channels?.web?.subject.en}
                    helperText={
                      (errors as any)?.input?.channels?.web?.subject.en
                    }
                  />
                  <Stack py={2}>
                    {parse(
                      (values as any)?.input?.channels?.web?.content?.en || ""
                    )}
                  </Stack>
                  <TextField
                    fullWidth
                    multiline
                    rows={15}
                    name="input.channels.web.content.en"
                    label={t("main.notification.notification.contentEn")}
                    value={
                      (values as any)?.input?.channels?.web?.content?.en || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.web.content.en",
                        e.target.value
                      );
                    }}
                  />
                  <Typography variant="h6" sx={{ my: 2 }}>
                    TR
                  </Typography>
                  <TextField
                    fullWidth
                    name="input.channels.web.subject.tr"
                    label={t("main.notification.notification.subjectTr")}
                    value={
                      (values as any)?.input?.channels?.web?.subject.tr || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.web.subject.tr",
                        e.target.value
                      );
                    }}
                    onBlur={() => handleBlur("input.channels.web.subject.tr")}
                    error={!!(errors as any)?.input?.channels?.web?.subject.tr}
                    helperText={
                      (errors as any)?.input?.channels?.web?.subject.tr
                    }
                  />
                  <Stack py={2}>
                    {parse(
                      (values as any)?.input?.channels?.web?.content?.tr || ""
                    )}
                  </Stack>
                  <TextField
                    fullWidth
                    multiline
                    rows={15}
                    name="input.channels.web.content.tr"
                    label={t("main.notification.notification.contentTr")}
                    value={
                      (values as any)?.input?.channels?.web?.content?.tr || ""
                    }
                    onChange={(e) => {
                      setFieldValue(
                        "input.channels.web.content.tr",
                        e.target.value
                      );
                    }}
                  />
                  <Autocomplete
                    multiple
                    freeSolo
                    size="small"
                    id="input.channels.web.variables"
                    options={[]}
                    value={(values as any).input?.channels.web?.variables || []}
                    inputValue={variable}
                    onInputChange={(event, newInputValue) => {
                      setVariable(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      setFieldValue("input.channels.web.variables", newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === ",") {
                        event.preventDefault();
                        const inputValueTrimmed = variable.trim();
                        if (inputValueTrimmed !== "") {
                          const updatedVariables = [
                            ...(values as any).input?.channels.web?.variables,
                            inputValueTrimmed,
                          ];
                          setFieldValue(
                            "input.channels.web.variables",
                            updatedVariables
                          );
                          setVariable("");
                        }
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            key={key}
                            size="small"
                            variant="outlined"
                            label={option}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t(
                          "main.notification.notification.variables"
                        )}
                        error={Boolean(
                          (errors as any).input?.channels.web?.variables
                        )}
                        helperText={
                          (errors as any).input?.channels.web?.variables
                        }
                      />
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                PUSH
              </AccordionSummary>
              <AccordionDetails>

                <Typography variant="h6" sx={{ my: 2 }}>EN</Typography>
                <TextField
                  fullWidth
                  name="input.channels.push.subject.en"
                  label={t("main.notification.notification.subjectEn")}
                  value={(values as any)?.input?.channels?.push?.subject.en || ""}
                  onChange={(e) => {
                    setFieldValue("input.channels.push.subject.en", e.target.value);
                  }}
                  onBlur={() => handleBlur("input.channels.push.subject.en")}
                  error={!!(errors as any)?.input?.channels?.push?.subject.en}
                  helperText={(errors as any)?.input?.channels?.push?.subject.en}
                />
                <ReactQuill
                  theme="snow"
                  value={(values as any)?.input?.channels?.push?.content.en || ""}
                  onChange={(value) => {
                    setFieldValue("input.channels.push.content.en", value);
                  }}
                  onBlur={() => handleBlur("input.channels.push.content.en")}
                  placeholder={t("main.notification.notification.contentEn")}
                  scrollingContainer={".ql-editor"}
                  style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
                />
                {(errors as any)?.input?.channels?.push?.content.en && (
                  <Typography color="error" variant="caption">
                    {(errors as any)?.input?.channels?.push?.content.en}
                  </Typography>
                )}

                <Typography variant="h6" sx={{ my: 2 }}>TR</Typography>
                <TextField
                  fullWidth
                  name="input.channels.push.subject.tr"
                  label={t("main.notification.notification.subjectTr")}
                  value={(values as any)?.input?.channels?.push?.subject.tr || ""}
                  onChange={(e) => {
                    setFieldValue("input.channels.push.subject.tr", e.target.value);
                  }}
                  onBlur={() => handleBlur("input.channels.push.subject.tr")}
                  error={!!(errors as any)?.input?.channels?.push?.subject.tr}
                  helperText={(errors as any)?.input?.channels?.push?.subject.tr}
                />
                <ReactQuill
                  theme="snow"
                  value={(values as any)?.input?.channels?.push?.content.tr || ""}
                  onChange={(value) => {
                    setFieldValue("input.channels.push.content.tr", value);
                  }}
                  onBlur={() => handleBlur("input.channels.push.content.tr")}
                  placeholder={t("main.notification.notification.contentTr")}
                  scrollingContainer={".ql-editor"}
                  style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
                />
                {(errors as any)?.input?.channels?.push?.content.tr && (
                  <Typography color="error" variant="caption">
                    {(errors as any)?.input?.channels?.push?.content.tr}
                  </Typography>
                )}
                <Autocomplete
                  multiple
                  freeSolo
                  size="small"
                  id="input.channels.push.variables"
                  options={[]}
                  value={(values as any).input?.channels.push?.variables || []}
                  inputValue={variable}
                  onInputChange={(event, newInputValue) => {
                    setVariable(newInputValue);
                  }}
                  onChange={(event, newValue) => {
                    setFieldValue("input.channels.push.variables", newValue);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ',') {
                      event.preventDefault();
                      const inputValueTrimmed = variable.trim();
                      if (inputValueTrimmed !== '') {
                        const updatedVariables = [...(values as any).input?.channels.push?.variables, inputValueTrimmed];
                        setFieldValue("input.channels.push.variables", updatedVariables);
                        setVariable('');
                      }
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={key}
                          size="small"
                          variant="outlined"
                          label={option}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={t("main.notification.notification.variables")}
                      error={Boolean((errors as any).input?.channels.push?.variables)}
                      helperText={(errors as any).input?.channels.push?.variables}
                    />
                  )}
                />

              </AccordionDetails>
            </Accordion> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateNotificationIsLoading}
            >
              {updateNotificationIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
