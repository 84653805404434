import userLocale from "../subdomains/user/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  user: {
    ...userLocale,
  },

  // TODO: import subdomain locales
};

export default locale;
