import {
  IUserListInput,
  IUserListRequest,
  IUserListResponse,
  IUserListResult,
  userDetailQuery,
  userListQuery,
  IUserDetailInput,
  IUserDetailRequest,
  IUserDetailResponse,
  IUserDetailResult,
  userDeleteQuery,
  IUserDeleteInput,
  IUserDeleteRequest,
  IUserDeleteResponse,
  IUserDeleteResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { userTags } from "./user.tags";
import { IGraphqlVariables } from "corede-common";

export const userApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    userList: builder.query<IUserListResult, IGraphqlVariables<IUserListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserListRequest,
        IUserListResponse,
        IUserListResult,
        IUserListInput
      >({
        query: userListQuery,
        providesTags: [userTags.users],
      })
    ),

    userDetail: builder.query<
      IUserDetailResult,
      IGraphqlVariables<IUserDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDetailRequest,
        IUserDetailResponse,
        IUserDetailResult,
        IUserDetailInput
      >({
        query: userDetailQuery,
        providesTags: [userTags.user],
      })
    ),

    // mutation

    userDelete: builder.mutation<
      IUserDeleteResult,
      IGraphqlVariables<IUserDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDeleteRequest,
        IUserDeleteResponse,
        IUserDeleteResult,
        IUserDeleteInput
      >({
        query: userDeleteQuery,
        invalidatesTags: [userTags.users],
      })
    ),
  }),
});

export const {
  useUserListQuery,
  useLazyUserListQuery,

  useUserDetailQuery,
  useLazyUserDetailQuery,

  useUserDeleteMutation,
} = userApi;
