

import { UserRouter } from "../subdomains/user/routes/UserRouter";
import { UserDomainLayout } from "../layouts";
import { userDomainRoutes } from "./user.base.route";
import { Route, Routes } from "react-router-dom";

export const UserDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<UserDomainLayout />}>
        <Route index element={<UserRouter />} />
        <Route
          path={`/${userDomainRoutes.subdomains.user.base}/*`}
          element={<UserRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
