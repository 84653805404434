

import { commonRoutes } from "routes/route.common";

export const userDomainRoutes = {
  base: "user",
  subdomains: {
    user: {
      base: "users",
    },
    // add subdomain route names here
  },
};

// User routes
export const baseUserRoute = (): string => `/${userDomainRoutes.base}/`;
export const listUserRoute = (): string =>
  `/${userDomainRoutes.base}/${userDomainRoutes.subdomains.user.base}`;
export const viewUserRoute = (id: string): string =>
  `/${userDomainRoutes.base}/${userDomainRoutes.subdomains.user.base}/${id}`;

// add subdomain routes here
