const locale = {
  user: "User",
  users: "Users",
  create: "Create User",
  update: "Update User",
  delete: "Delete User",
  view: "View User",
  list: "List Users",
  createdSuccessfully: "User created successfully",
  updatedSuccessfully: "User updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "User deleted successfully",


  // {t("main.user.user.name")}</b> {view
  //   {t("main.user.user.surname")}</b> {v
  //   {t("main.user.user.description")}</b
  //   {t("main.user.user.acceptedPolicies"
  //   {t("main.user.user.address")}</b> {v
  //   {t("main.user.user.birthDate")}</b> 
  //   {t("main.user.user.city")}</b> {view
  //   {t("main.user.user.country")}</b> {v
  //   {t("main.user.user.createdAt")}</b> 
  //   {t("main.user.user.department")}</b>
  //   {t("main.user.user.email")}</b> {vie
  //   {t("main.user.user.gender")}</b> {vi
  //   {t("main.user.user.language")}</b> {
  //   {t("main.user.user.lastLoginDate")}<
  //   {t("main.user.user.organization")}</
  //   {t("main.user.user.phoneNumber")}</b
  //   {t("main.user.user.profileImage")}</
  //   {t("main.user.user.role")}</b> {view
  //   {t("main.user.user.status")}</b> {vi
  //   {t("main.user.user.type")}</b> {view
  //   {t("main.user.user.updatedAt")}</b> 

  name: "Name",
  surname: "Surname",
  description: "Description",
  acceptedPolicies: "Accepted Policies",
  address: "Address",
  birthDate: "Birth Date",
  city: "City",
  country: "Country",
  createdAt: "Created At",
  department: "Department",
  email:  "Email",
  gender: "Gender",
  language: "Language",
  lastLoginDate: "Last Login Date",
  organization: "Organization",
  phoneNumber: "Phone Number",
  profileImage: "Profile Image",
  role: "Role",
  status: "Status",
  type: "Type",
  updatedAt: "Updated At",
  

};

export default locale;
