import blogLocale from "../domains/blog/locales/en";
import userLocale from "../domains/user/locales/en";
import paymentProductLocale from "../domains/paymentProduct/locales/en";
import notificationLocale from "../domains/notification/locales/en";
import helpCenterLocale from "../domains/helpCenter/locales/en";
import subscriptionLocale from "../domains/subscription/locales/en";
import contactFormLocale from "../domains/contactForm/locales/en";
import webinarLocale from "../domains/webinar/locales/en";
import policyLocale from "../domains/policy/locales/en";
import formLocale from "../domains/form/locales/en";
import faqLocale from "../domains/faq/locales/en";
import dashboardLocale from "../domains/dashboard/locales/en";

const locale = {
  dashboard: {
    ...dashboardLocale,
  },
  blog: {
    ...blogLocale,
  },
  faq: {
    ...faqLocale,
  },
  form: {
    ...formLocale,
  },
  policy: {
    ...policyLocale,
  },
  webinar: {
    ...webinarLocale,
  },
  contactForm: {
    ...contactFormLocale,
  },
  subscription: {
    ...subscriptionLocale,
  },
  helpCenter: {
    ...helpCenterLocale,
  },
  notification: {
    ...notificationLocale,
  },
  paymentProduct: {
    ...paymentProductLocale,
  },
  user: {
    ...userLocale,
  },
};

export default locale;
