import {
  Grid,
  List as ListMui,
  Stack,
  ListItem,
  Button,
  Skeleton,
  TableCell,
  TableRow,
  TableBody,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableSortLabel,
  Chip,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import {
  createNotificationRoute,
  updateNotificationRoute,
  viewNotificationRoute,
} from "../../../routes/notification.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNotificationsQuery } from "../context/notification.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { Iconify, MyTablePagination } from "components";
import {
  IBaseError,
  IGraphqlVariables,
  Language,
  TNullable,
} from "corede-common";
import {
  IBaseNotificationEntity,
  INotificationListInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { Link, useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseNotificationEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<INotificationListInput>
  >({
    input: {
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: notificationsData,
    isLoading: notificationsIsLoading,
    error: notificationsError,
  } = useNotificationsQuery(variables);

  // mutations

  // constants

  // action handlers
  // const handleEdit = (id: string) => {
  //   navigate(updateNotificationRoute(id));
  // };

  const handleDelete = (item: IBaseNotificationEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewNotificationRoute(id));
  };

  const handleSortRequest = (sortName: string) => {
    sortName === "index"
      ? setVariables({
          ...variables,
          input: {
            ...variables.input,
            pagination: {
              ...variables.input?.pagination,
              sort: undefined,
            },
          },
        })
      : setVariables({
          ...variables,
          input: {
            ...variables.input,
            pagination: {
              ...variables.input?.pagination,
              sort: {
                [sortName]:
                  variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1,
              },
            },
          },
        });
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      notificationsError as IBaseError,
      currentLanguage
    );
  }, [notificationsError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(
      notificationActions.setTitle(
        t("main.notification.notification.notifications")
      )
    );
    dispatch(notificationActions.setBackButton(false));
    // dispatch(
    //   notificationActions.setRightButton({
    //     title: t(`main.notification.notification.create`),
    //     path: createNotificationRoute(),
    //     icon: null,
    //   })
    // );
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      {/* <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>

        <Stack direction="row" gap={2}>
          <Typography variant="h5">{t("main.helpCenter.helpCenter.filters")}</Typography>
          <Stack direction="row" gap={1}>
            {
              Object.values(Language).map((language) => (
                <Chip
                  key={language}
                  label={language}
                  variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                  onClick={() => {
                    setVariables({
                      ...variables,
                      input: {
                        ...variables.input,
                        filter: {
                          ...(variables.input?.filter || {}),
                          languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                        },
                      },
                    });
                  }}
                />
              ))
            }
          </Stack>
        </Stack>
      </Stack> */}
      <Stack direction="row" justifyContent="space-between">
        <Card sx={{ p: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.index !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.index === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("index")}
                    >
                      #
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.name !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.name === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("name")}
                    >
                      {t("name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.domain !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.domain === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("domain")}
                    >
                      {t("domain")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.type !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.type === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("type")}
                    >
                      {t("type")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t("email")}</TableCell>
                  <TableCell>Web</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.createdAt !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.createdAt === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("createdAt")}
                    >
                      {t("createdAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.updatedAt !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.updatedAt === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("updatedAt")}
                    >
                      {t("updatedAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">{t("actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!notificationsIsLoading
                  ? notificationsData?.data?.map((notification, index) => (
                      <TableRow
                        key={notification._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {(pagination.page ?? 0) * pagination.pageSize +
                            index +
                            1}
                        </TableCell>
                        <TableCell>{notification.name}</TableCell>
                        <TableCell>{notification.domain}</TableCell>
                        <TableCell>{notification.type}</TableCell>
                        <TableCell>
                          {notification.channels.email ? "+" : "-"}
                        </TableCell>
                        <TableCell>
                          {notification.channels.web ? "+" : "-"}
                        </TableCell>
                        <TableCell>
                          {moment(notification.createdAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(notification.updatedAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent={"flex-end"}
                            gap={1}
                            flexWrap={"wrap"}
                          >
                            {/* <Button
                            onClick={() => handleView(notification._id)}
                            variant="outlined"
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <RemoveRedEye sx={{ fontSize: "16px" }} />
                          </Button> */}
                            <Link
                              to={updateNotificationRoute(notification._id)}
                              target="_blank"
                            >
                              <Button
                                variant="outlined"
                                size="small"
                                sx={{ height: 32, minWidth: 10 }}
                              >
                                <Edit sx={{ fontSize: "16px" }} />
                              </Button>
                            </Link>
                            <Button
                              onClick={() => handleDelete(notification)}
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{ height: 32, minWidth: 10 }}
                            >
                              <DeleteIcon sx={{ fontSize: "16px" }} />
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  : [1, 2, 3].map((key) => (
                      <Stack
                        key={key}
                        direction="row"
                        justifyContent="space-between"
                        width={340}
                        alignItems={"center"}
                        p={2}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={340}
                          height={60}
                          sx={{ borderRadius: 2 }}
                        />
                      </Stack>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={notificationsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value),
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
