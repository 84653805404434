import {
  Grid,
  Stack,
  Button,
  Skeleton,
  TableCell,
  TableRow,
  TableBody,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableSortLabel,
  Avatar,
} from "@mui/material";
import { useAppDispatch } from "context";
import { userActions } from "../../../context";
import { viewUserRoute } from "../../../routes/user.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useUserListQuery } from "../context/user.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { Iconify, MyTablePagination } from "components";
import {
  IBaseError,
  IGraphqlVariables,
  Language,
  TNullable,
} from "corede-common";
import { IBaseUserEntity, IUserListInput } from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";
import Delete from "./Delete";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseUserEntity>>(null);
  const [variables, setVariables] = useState<IGraphqlVariables<IUserListInput>>(
    {
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    }
  );

  // queries
  const {
    data: usersData,
    isLoading: usersIsLoading,
    error: usersError,
  } = useUserListQuery(variables);

  // mutations

  // constants

  // action handlers

  const handleView = (id: string) => {
    navigate(viewUserRoute(id));
  };

  const handleDelete = (item: IBaseUserEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleSortRequest = (sortName: string) => {
    sortName === "index"
      ? setVariables({
          ...variables,
          input: {
            ...variables.input,
            pagination: {
              ...variables.input?.pagination,
              sort: undefined,
            },
          },
        })
      : setVariables({
          ...variables,
          input: {
            ...variables.input,
            pagination: {
              ...variables.input?.pagination,
              sort: {
                [sortName]:
                  variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1,
              },
            },
          },
        });
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(usersError as IBaseError, currentLanguage);
  }, [usersError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(userActions.setTitle(t("main.user.user.users")));
    dispatch(userActions.setBackButton(false));
    dispatch(userActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      {/* <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>

        <Stack direction="row" gap={2}>
          <Typography variant="h5">{t("main.helpCenter.helpCenter.filters")}</Typography>
          <Stack direction="row" gap={1}>
            {
              Object.values(Language).map((language) => (
                <Chip
                  key={language}
                  label={language}
                  variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                  onClick={() => {
                    setVariables({
                      ...variables,
                      input: {
                        ...variables.input,
                        filter: {
                          ...(variables.input?.filter || {}),
                          languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                        },
                      },
                    });
                  }}
                />
              ))
            }
          </Stack>
        </Stack>
      </Stack> */}
      <Stack direction="row" justifyContent="space-between">
        <Card sx={{ p: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.index !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.index === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("index")}
                    >
                      #
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t("Image")}</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.name !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.name === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("name")}
                    >
                      {t("name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.surname !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.surname === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("surname")}
                    >
                      {t("surname")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.email !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.email === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("email")}
                    >
                      {t("email")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.lastLoginDate !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.lastLoginDate === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("lastLoginDate")}
                    >
                      {t("LastLoginDate")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.createdAt !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.createdAt === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("createdAt")}
                    >
                      {t("createdAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.updatedAt !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.updatedAt === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("updatedAt")}
                    >
                      {t("updatedAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">{t("actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!usersIsLoading
                  ? usersData?.data?.map((user, index) => (
                      <TableRow
                        key={user._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {(pagination.page ?? 0) * pagination.pageSize +
                            index +
                            1}
                        </TableCell>
                        <TableCell>
                          <Avatar src={user.profileImage?.thumbnailPublicUrl} />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.surname}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          {user?.lastLoginDate
                            ? moment(user.lastLoginDate).format("DD-MM-YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {moment(user.createdAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(user.updatedAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent={"flex-end"}
                            gap={1}
                            flexWrap={"wrap"}
                          >
                            <Button
                              onClick={() => handleView(user._id)}
                              variant="outlined"
                              size="small"
                              sx={{ height: 32, minWidth: 10 }}
                            >
                              <RemoveRedEye sx={{ fontSize: "16px" }} />
                            </Button>

                            <Button
                              onClick={() => handleDelete(user)}
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{ height: 32, minWidth: 10 }}
                            >
                              <DeleteIcon sx={{ fontSize: "16px" }} />
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  : [1, 2, 3].map((key) => (
                      <Stack
                        key={key}
                        direction="row"
                        justifyContent="space-between"
                        width={340}
                        alignItems={"center"}
                        p={2}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={340}
                          height={60}
                          sx={{ borderRadius: 2 }}
                        />
                      </Stack>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={usersData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value),
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
