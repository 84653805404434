import { createSlice } from "@reduxjs/toolkit";
import { commonInitialState, commonReducers } from "context/slices";

const { actions, reducer } = createSlice({
  name: "user",
  initialState: {
    ...commonInitialState,
  },
  reducers: {
    ...commonReducers,
  },
});

export const userActions = actions;
export const userSlice = reducer;
