import {
  Avatar,
  Card,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUserDetailQuery } from "../context/user.api";
import { useAppDispatch } from "context";
import { userActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listUserRoute } from "../../../routes/user.base.route";
import moment from "moment";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listUserRoute());
  }
  // queries
  const {
    data: viewUserData,
    isLoading: viewUserIsLoading,
    error: viewUserError,
  } = useUserDetailQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(viewUserError as IBaseError, currentLanguage);
  }, [viewUserError, currentLanguage]);

  useEffect(() => {
    dispatch(userActions.setTitle(t("main.user.user.view")));
    dispatch(userActions.setBackButton(true));
    dispatch(userActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {viewUserIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Card sx={{ p: 4 }}>
              {/* setValues({
        input: {
          name: userData.name ?? "",
          description: userData.description ?? "",
          baseCurrency: userData.baseCurrency ?? Currency.USD,
          isRefundable: userData.isRefundable ?? false,
          maxCount: userData.maxCount ?? 0,
          userStatus: userData.userStatus ?? UserStatus.active,
          soldOut: userData.soldOut ?? false,
          isUpgradable: userData.isUpgradable ?? false,
          recurring: {
            level: userData.recurring?.level ?? 0,
            monthly: {
              packageName: userData.recurring?.monthly?.packageName ?? "",
              entitlements: {
                storageLimit: userData.recurring?.monthly?.entitlements?.storageLimit ?? 0,
                userLimit: userData.recurring?.monthly?.entitlements?.userLimit ?? 0
              },
              pricing: {
                basePrice: userData.recurring?.monthly?.pricing?.basePrice ?? 0,
                discountedPrice: userData.recurring?.monthly?.pricing?.discountedPrice ?? 0,
                discountRate: userData.recurring?.monthly?.pricing?.discountRate ?? 0,
                salesPrices: userData.recurring?.monthly?.pricing?.salesPrices ?? [],
              },
              interval: userData.recurring?.monthly?.interval ?? RecurringInterval.monthly,
              integrations: userData.recurring?.monthly?.integrations ?? undefined,
            },
            yearly: {
              packageName: userData.recurring?.yearly?.packageName ?? "",
              entitlements: {
                storageLimit: userData.recurring?.yearly?.entitlements?.storageLimit ?? 0,
                userLimit: userData.recurring?.yearly?.entitlements?.userLimit ?? 0
              },
              pricing: {
                basePrice: userData.recurring?.yearly?.pricing?.basePrice ?? 0,
                discountedPrice: userData.recurring?.yearly?.pricing?.discountedPrice ?? 0,
                discountRate: userData.recurring?.yearly?.pricing?.discountRate ?? 0,
                salesPrices: userData.recurring?.yearly?.pricing?.salesPrices ?? [],
              },
              interval: userData.recurring?.yearly?.interval ?? RecurringInterval.yearly,
              integrations: userData.recurring?.yearly?.integrations ?? undefined,
            },
            daily: userData.recurring?.daily,
            weekly: userData.recurring?.weekly,
            mainSubscription: MainSubscription.main
          },
          relatedFeatures: userData.relatedFeatures,
          restrictedFeatures: userData.restrictedFeatures,
          video: userData.video,
          image: userData.image,
          integrations: userData.integrations,
          pricing: userData.pricing,
          productType: userData.productType,
          hasShipping: userData.hasShipping,
          shippingPrice: userData.shippingPrice,
          quota: userData.quota
        },
      }); */}
              <Avatar
                alt={viewUserData?.name}
                src={viewUserData?.profileImage?.publicUrl}
                sx={{ width: 100, height: 100, mb:2 }}
              />
              <Typography>
                <b>{t("main.user.user.name")}: </b> {viewUserData?.name}
              </Typography>
              <Typography>
                <b>{t("main.user.user.surname")}: </b> {viewUserData?.surname}
              </Typography>
              {/* <Typography><b>{t("main.user.user.organization")}: </b> {(viewUserData as any)?.organization?._id}</Typography> */}
              <Typography>
                <b>{t("main.user.user.email")}: </b> {viewUserData?.email}
              </Typography>
              <Typography>
                <b>{t("main.user.user.gender")}: </b>{" "}
                {viewUserData?.gender ?? "-"}
              </Typography>
              <Typography>
                <b>{t("main.user.user.language")}: </b>{" "}
                {viewUserData?.language ?? "-"}
              </Typography>
              <Typography>
                <b>{t("main.user.user.birthDate")}: </b>{" "}
                {viewUserData?.birthDate
                  ? moment(viewUserData?.birthDate).format("DD.MM.YYYY")
                  : "-"}
              </Typography>
              <Typography>
                <b>{t("main.user.user.status")}: </b> {viewUserData?.status}
              </Typography>
              <Typography>
                <b>{t("main.user.user.description")}: </b>{" "}
                {viewUserData?.description ?? "-"}
              </Typography>
              {/* <Typography><b>{t("main.user.user.department")}: </b> {viewUserData?.department?._id}</Typography> */}

              <Typography height={10}> </Typography>
              <Typography>
                <b>{t("main.user.user.phoneNumber")}: </b>{" "}
                {viewUserData?.phoneNumber ?? "-"}
              </Typography>
              <Typography>
                <b>{t("main.user.user.country")}: </b>{" "}
                {viewUserData?.country ?? "-"}
              </Typography>
              <Typography>
                <b>{t("main.user.user.city")}: </b> {viewUserData?.city ?? "-"}
              </Typography>
              <Typography>
                <b>{t("main.user.user.address")}: </b>{" "}
                {viewUserData?.address ?? "-"}
              </Typography>
              <Typography height={10}> </Typography>
              {/* <Typography><b>{t("main.user.user.acceptedPolicies")}: </b> {viewUserData?.acceptedPolicies?.map((policy) => policy.title).join(", ")}</Typography> */}
              <Typography>
                <b>{t("main.user.user.lastLoginDate")}: </b>{" "}
                {moment(viewUserData?.lastLoginDate).format("DD.MM.YYYY")}
              </Typography>
              <Typography>
                <b>{t("main.user.user.createdAt")}: </b>{" "}
                {moment(viewUserData?.createdAt).format("DD.MM.YYYY")}
              </Typography>
              <Typography>
                <b>{t("main.user.user.updatedAt")}: </b>{" "}
                {moment(viewUserData?.updatedAt).format("DD.MM.YYYY")}
              </Typography>
            </Card>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
