const locale = {
  user: "User",
  users: "Users",
  create: "Create User",
  update: "Update User",
  delete: "Delete User",
  view: "View User",
  list: "List Users",
  createdSuccessfully: "User created successfully",
  updatedSuccessfully: "User updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "User deleted successfully",
};

export default locale;
